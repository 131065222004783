import * as React from "react";
import Layout from "../../components/Layout";
import { graphql, PageProps, withPrefix } from "gatsby";
import { LocalisedPageContext } from "../../types/PageTypes";
import {
  ImageInterface,
  LandingSectionsInterface,
  LocalizedContextInterface,
  SanityColorList
} from "../../types/SanityTypes";
import SEO from "../../components/Seo";
import Helmet from "react-helmet";
import StructuredData from "../../components/StructuredData";
import HeroBanner from "../../components/HeroBanner";
import BlockRenderer from "../../components/BlockRenderer";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { getLocalizedPath } from "../../utils/gatsbyBuild";

type NotFoundProps = {
  sanityFourOhFour: {
    _id: string;
    banner: {
      heading: string;
      subHeading?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      _rawHeroVideo: any;
      primaryColor: SanityColorList;
      isVideoBanner: boolean;
    };
    landingSections: LandingSectionsInterface[];
    seo: {
      metaTitle: string;
      metaDescription: string;
    };
    slug: {
      current: string;
    };
  };
} & LocalizedContextInterface;

type NotFoundContext = LocalisedPageContext;

export const query = graphql`
  query NotFound($_id: String, $language: String) {
    sanityFourOhFour(_id: { eq: $_id }) {
      _id
      name
      slug {
        current
      }
      seo {
        metaDescription
        metaTitle
      }
      banner {
        heading
        subHeading
        primaryColor {
          value
          title
        }
        contentAlignment
        isVideoBanner
        _rawHeroImage(resolveReferences: { maxDepth: 10 })
        _rawHeroVideo(resolveReferences: { maxDepth: 10 })
      }
      landingSections {
        ...SanityTextEditorBlockFieldsFull
      }
    }
    ...LocalizedContext
  }
`;

const NotFound = (props: PageProps<NotFoundProps, NotFoundContext>) => {
  const fourOhFour = props.data.sanityFourOhFour;
  const language = props.pageContext.language;
  const { htmlLang, siteUrl } = useSiteMetadata(language);
  const pageTitle = fourOhFour.banner.heading;
  const seoTitle = fourOhFour.seo?.metaTitle;
  const seoDescription = fourOhFour.seo?.metaDescription;
  const localizedPath = withPrefix(getLocalizedPath(fourOhFour.slug?.current, fourOhFour._id));

  return (
    <Layout localizedContext={{ ...props.pageContext, ...props.data }} pageName={pageTitle} pageType={"404"}>
      <StructuredData
        type={"404"}
        data={fourOhFour.banner._rawHeroImage}
        language={htmlLang}
        slug={`/${fourOhFour.slug.current}/`}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO
        title={fourOhFour.seo.metaTitle}
        description={fourOhFour.seo.metaDescription}
        imageUrl={fourOhFour.banner._rawHeroImage.asset.url}
        imageAlt={fourOhFour.banner._rawHeroImage.alt}
      />
      <div className="page_404">
        <HeroBanner
          sanityImage={fourOhFour.banner._rawHeroImage}
          title={fourOhFour.banner.heading}
          alignment={fourOhFour.banner.contentAlignment}
          isVideoBanner={fourOhFour.banner.isVideoBanner}
          video={fourOhFour.banner._rawHeroVideo}
        />
        <section data-testid="404">
          {fourOhFour.landingSections &&
            fourOhFour.landingSections.map((section: any, index: number) => (
              <BlockRenderer key={"landingSection" + index} section={section} />
            ))}
        </section>
      </div>
    </Layout>
  );
};

export default NotFound;
